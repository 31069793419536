import { useEffect, useRef, useState } from "react";
import GradientSearchIcon from "../../asset/svg/GradientSearchIcon";
import SoloLogo from "../../asset/svg/SoloLogo";
import { Xmark } from "iconoir-react";

const SearchField = ({ value, onChange, onPressEnter, onClear, ...other }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);
    return (
        <div className="relative flex items-center bg-white px-3 rounded-lg h-[52px]"
            style={{
                border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                backgroundImage: hasFocus ? 'linear-gradient(#FFF, #FFF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                backgroundOrigin: 'border-box',
                backgroundClip: 'padding-box, border-box',
                outline: 'none'
            }}>
            <div className="cursor-pointer" onClick={() => { onPressEnter(value); }}>
                <GradientSearchIcon />
            </div>

            <input className="h-full py-3 w-full bg-transparent border-none outline-none pl-4"
                ref={ref}
                value={value}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(event) => onChange(event.target.value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onPressEnter(event.target.value);
                    }
                }}
                {...other}
            />

            {
                value ? (
                    <div onClick={() => {
                        onClear();
                        onChange('');
                    }} className="cursor-pointer">
                        <Xmark width={20} height={20} color="#8B8699" />
                    </div>
                ) : (
                    <div>
                        <SoloLogo />
                    </div>
                )
            }
        </div>
    )
}
export default SearchField;