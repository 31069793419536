import { useSelector } from "react-redux";
import AuthGuard from "./authGuard";
import { Link, Navigate } from "react-router-dom";
import moment from "moment";
import DialogBox from "../component/DialogBox";
import TrialExpireImg from '../asset/svg/FreeTrialExpire.svg'
const FreeTrialExpireBg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="276" viewBox="0 0 96 276" fill="none">
            <path d="M-36.6045 -55L69.6626 56.9821C96.6949 85.4681 97.1761 129.984 70.766 159.047L-31.7689 271.885" stroke="url(#paint0_linear_237_9268)" strokeWidth="11" />
            <defs>
                <linearGradient id="paint0_linear_237_9268" x1="-9.96023" y1="227.953" x2="157.887" y2="75.4528" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8773FF" />
                    <stop offset="0.505" stopColor="#FFC0FA" />
                    <stop offset="1" stopColor="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const FreeTrialExpire = ({ username }) => {
    return (
        <DialogBox>
            <div className="relative p-8 w-[680px] bg-white rounded-[25px] overflow-hidden">
                <div className="top-0 left-0 absolute">
                    <FreeTrialExpireBg />
                </div>
                <div className="text-center flex flex-col items-center justify-center">
                    <div>
                        <img src={TrialExpireImg} alt="expire" />
                    </div>
                    <p className="text-bodyOneBold">Hey {username}, Your SuperPen Free Trial has been <span className="text-venus-default">expired</span>
                    </p>
                    <p className="mt-2 text-bodyOne text-gray-5">To continue using SuperPen upgrade now </p>
                    <Link to={'/payment-pending'} className="filled-btn mt-6">Subscribe now</Link>
                </div>
            </div>
        </DialogBox>
    )
}

const PaymentGuard = ({ children }) => {
    const { name, subscription, subscriptionLimit } = useSelector((state) => state.auth);

    if (!subscription || !subscription.endDate) {
        return <Navigate to='/payment-pending' />;
    }

    const isTrialExpired = subscription.isFreeTrial && moment(subscription.endDate).isBefore(moment());
    const isSubscriptionExpired = !subscription.isFreeTrial && moment(subscription.endDate).isBefore(moment());
    const isAiLimitExceeded = subscription.isFreeTrial && subscriptionLimit.aiCreditLimit < subscriptionLimit.aiCreditUsage;
    const isSearchLimitExceeded = subscription.isFreeTrial && subscriptionLimit.searchLimit < subscriptionLimit.searchUsage;

    // console.log({
    //     aiCreditLimit: subscriptionLimit.aiCreditLimit,
    //     searchLimit: subscriptionLimit.searchLimit,
    //     aiCreditUsage: subscriptionLimit.aiCreditUsage,
    //     searchUsage: subscriptionLimit.searchUsage,
    //     isAiLimitExceeded,
    //     isSearchLimitExceeded
    // })
    if (isAiLimitExceeded || isSearchLimitExceeded || isTrialExpired ) {
        return (
            <AuthGuard>
                <FreeTrialExpire username={name} />
                {children}
            </AuthGuard>
        );
    }

    if (isSubscriptionExpired) {
        return <Navigate to='/payment-failed' />;
    }

    return (
        <AuthGuard>
            {children}
        </AuthGuard>
    );
};

export default PaymentGuard;
