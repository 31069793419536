import api from "../utils/api";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

export const fetchLinkedInPosts = async (prop) => {
    try {
        const response = await api.get('/linkedin/posts', {
            params: prop
        });

        if (prop.search) {
            trackEvent(MIXPANEL_EVENTS.SEARCH_KEYWORD, {
                "Keyword searched": prop.search,
                "Number of results": response?.data?.totalCount
            })
        }

        if (response?.data?.data?.length === 0) {
            trackEvent(MIXPANEL_EVENTS.NO_POSTS_FOUND, { "Keyword searched": prop.search });
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};