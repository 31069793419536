export const MIXPANEL_EVENTS = {
    SIGNUP_CLICKED: 'signup_clicked',
    SIGNUP_VIEWED: 'signup_viewed',
    SIGNUP_SUCCESS: 'signup_success',
    SIGNUP_FAILURE: 'signup_failure',
    T_C_VIEWED: 't&c_viewed',
    LOGIN_CLICKED: 'login_clicked',
    LOGIN_VIEWED: 'login_viewed',
    OTP_SENT: 'otp_sent',
    OTP_RE_REQUESTED: 'otp_re-requested',
    OTP_SUBMITTED: 'otp_submitted',
    LOGIN_SUCCESS: 'login_success',
    LOGIN_FAILURE: 'login_failure',
    FORGOT_PW_CLICKED: 'forgot_pw_clicked',
    RESET_PW_CLICKED: 'reset_pw_clicked',
    RESET_PW_SENT: 'reset_pw_sent',
    RESET_RE_REQUESTED: 'reset_re-requested',
    SET_PW_CLICKED: 'set_pw_clicked',
    RESET_PW_SUCCESS: 'reset_pw_success',
    RESET_PW_FAILURE: 'reset_pw_failure',
    PERSONA_VIEW: 'persona_view',
    LINKEDIN_CONNECT_CLICKED: 'linkedin_connect_clicked',
    LINKEDIN_CONNECT_SUCCESS: 'linkedin_connect_success',
    LINKEDIN_CONNECT_FAILURE: 'linkedin_connect_failure',
    ONBOARDING_NEXT: 'onboarding_next',
    ONBOARDING_BACK: 'onboarding_back',
    LINKEDIN_SKIP: 'linkedin_skip',
    PRIVACY_VIEWED: 'privacy_viewed',
    ONBOARDING_DROPOFF: 'onboarding_dropoff',
    PAYMENT_VIEWED: 'payment_viewed',
    PLAN_TOGGLED: 'plan_toggled',
    PAYMENT_INITIATED: 'payment_initiated',
    AMOUNT_RECEIVED: 'amount_received',
    PAYMENT_FAILED: 'payment_failed',
    PAYMENT_RETRIED: 'payment_retried',
    FILTER_CLICKED: 'filter_clicked',
    FILTER_LIKES: 'filter_likes',
    FILTER_TONE: 'filter_tone',
    FILTER_LENGTH: 'filter_length',
    SEARCH_KEYWORD: 'search_keyword',
    SUGGESTION_CLICKED: 'suggestion_clicked',
    FILTERS_REMOVED: 'filters_removed',
    NO_POSTS_FOUND: 'no_posts_found',
    POST_CLICK: 'post_click',
    COMMENTS_SELECTED: 'comments_selected',
    MANUALLY_CLICKED: 'manually_clicked',
    AI_CLICKED: 'ai_clicked',
    COMMENTS_CANCEL: 'comments_cancel',
    WRITING_BACK: 'writing_back',
    CONTENT_COPY: 'content_copy',
    PREVIEW_CLICKED: 'preview_clicked',
    PREVIEW_BACK: 'preview_back',
    SETTINGS_VIEWED: 'settings_viewed',
    RENEW_CLICKED: 'renew_clicked',
    INVOICE_VIEWED: 'invoice_viewed',
    CANCEL_REQUEST: 'cancel_request',
    LOGOUT_CLICKED: 'logout_clicked',
    SEE_MORE_CLICKED:'see_more_clicked',
    RECREATE_CLICKED:'recreate_clicked',
};
