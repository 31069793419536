import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ArrowLeft, ArrowUp, Copy, FastArrowLeft, FastArrowRight } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import ContentExpand from "../../component/ContentExpand";
import LinkedInPostCard from "../../component/LinkedinPostCard";
import { copyToClipboard } from "../../component/text-editor/copyClipboard";
import { incrementUserProperty, trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const LeftSide = ({ content, selectedComment }) => {
    const [show, setShow] = useState(true);
    const handleToggle = () => {
        setShow(prev => !prev);
    }
    return (
        <div className="rounded-[20px] p-4 overflow-auto h-[95vh] scrollbar" style={{
            background: 'rgba(245, 242, 255, 0.40)'
        }}>
            <div className="flex items-center gap-1">
                <LightBulbIcon />
                <p className="text-bodyOneBold text-star-dark">Your Inspiration</p>
                {
                    show && (
                        <div className="flex gap-2 text-bodyTwo text-star-default items-center cursor-pointer ml-auto" onClick={handleToggle}>
                            <p>Hide</p>
                            <FastArrowLeft />
                        </div>
                    )
                }
            </div>
            {
                !show && (
                    <div className="flex gap-2 justify-end text-bodyTwo text-star-default items-center cursor-pointer mt-2" onClick={handleToggle}>
                        <p>Show</p>
                        <FastArrowRight />
                    </div>
                )
            }
            {
                show && (
                    <>
                        <p className="text-bodyTwo text-space-default my-4">
                            Reference post
                        </p>
                        <div className="rounded-xl bg-white p-4">
                            <ContentExpand content={content} maxLines={3} />
                        </div>
                        <div className="mt-4">
                            {
                                selectedComment?.length > 0 && (
                                    <p className="text-bodyTwo text-space-default">Interesting comments</p>
                                )
                            }
                            <div className="flex flex-col mt-4 gap-4">
                                {
                                    selectedComment?.map((comment) => (
                                        <div key={comment._id} className="rounded-xl p-2 bg-star-light4 flex gap-2">
                                            <div className="min-w-5">
                                                <ThumbsUpIcon firstColor="#5F38DB" secondColor="#A489F3" />
                                            </div>
                                            <p className="text-bodyTwo text-gray-6">
                                                {comment?.commentText}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

const PostPreview = ({ postContent }) => {
    const authState = useSelector((state) => state.auth);

    const firstPost = '<p>Feeling inspired by the latest AI advancements! Who else is blown away by the possibilities of technology shaping our future? ...more</p>';
    const thirdPost = '<p>In my 25 years of building startups, I’ve noticed a shift: We’ve stopped listening to founders and started listening to VCs on how to build ...more</p>';
    return (
        <div className="flex justify-center">
            <div className="flex flex-col items-center justify-center gap-2.5">
                <div className="relative">
                    <LinkedInPostCard content={firstPost} name={'John Smith'} headLine={'Technical Recruiter'} />
                    <div className="absolute top-0 left-0 w-full h-full" style={{
                        background: "#f4f1eda3"
                    }} />
                </div>
                <LinkedInPostCard content={postContent} name={authState?.name} profilePhoto={authState?.profilePicture} />
                <div className="relative">
                    <LinkedInPostCard content={thirdPost} name={'Evelyn Harper'} headLine={'Senior Data Scientist'} />
                    <div className="absolute top-0 left-0 w-full h-full" style={{
                        background: "#f4f1eda3"
                    }} />
                </div>
            </div>
        </div>
    )
}

const getHTML = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

const PreviewScreen = (props) => {
    const containerRef = useRef(null);

    const {
        selectedComment,
        content,
        handleBack,
        editorState
    } = props;

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // Calculate the scroll position for center
            const scrollX = (container.scrollWidth - container.clientWidth) / 2;
            const scrollY = (container.scrollHeight - container.clientHeight) / 2;
            container.scrollTo({
                top: scrollY,
                left: scrollX,
                behavior: 'smooth' // Enables smooth scrolling
            });
        }
    }, []);

    const trackCopyClicked = () => {
        trackEvent(MIXPANEL_EVENTS.CONTENT_COPY, {
            "Copied from": 'preview_screen',
            "Post Id": props.postId,
            "Post Source": props.source,
            "Generated with AI": props.isAiGenerated,
            "Manual changes": !props.isAiGenerated,
        })

        incrementUserProperty('post_copied');
    }
    const trackBackClicked = () => {
        trackEvent(MIXPANEL_EVENTS.PREVIEW_BACK, {
            "Post Id": props.postId,
            "Generated with AI": props.isAiGenerated,
            "Manual changes": !props.isAiGenerated
        })
    }
    return (
        <div className="z-50 h-screen w-screen fixed top-0 left-0 p-5 bg-star-light2">
            <div className="flex  gap-5 h-[95vh]">

                {/* Left Side */}
                <div className="max-w-[32%]">
                    <LeftSide content={content} selectedComment={selectedComment} />
                </div>

                {/* Right Side */}
                <div className="flex flex-1 flex-col gap-5 rounded-[20px] bg-gray-moon p-[30px] h-[95vh]">
                    <div className="flex items-center justify-center gap-2">
                        {/* <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" /> */}
                        <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                    </div>
                    <div>
                        <p className="text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Here's how your post would look
                        </p>
                        <p className="text-bodyOne">
                            Please read carefully before posting
                        </p>
                    </div>
                    <div ref={containerRef} className="h-full overflow-auto scrollbar bg-[#F4F1ED] rounded-[10px] ">
                        <PostPreview postContent={getHTML(editorState)} />
                    </div>
                    <div className="flex justify-between items-center mt-auto">
                        <button className="text-btn small" onClick={() => {
                            handleBack();
                            trackBackClicked();
                        }}> <ArrowLeft /> Back</button>
                        <div className="flex gap-2">
                            <button className="filled-btn small" onClick={() => {
                                trackCopyClicked();
                                copyToClipboard(editorState)
                            }}>
                                <Copy />
                                Copy 
                            </button>
                            <div className="tooltip-wrapper">
                                <button className="outlined-btn small" disabled>
                                    <ArrowUp />
                                    Post on LinkedIn
                                </button>
                                <div className="tooltip hidden absolute bg-white -top-[45px] right-0 px-4 py-2 cursor-default"
                                    style={{
                                        boxShadow: "0px 14px 32px 0px rgba(39, 39, 47, 0.06)",
                                        borderRadius: "8px 8px 0px 8px"
                                    }}>
                                    <p className="text-bodyThreeBold bg-heading-gradient-3 bg-clip-text text-transparent">
                                        Coming soon!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PreviewScreen;