export const isObjectEmpty = (obj) => {
    if (typeof obj !== 'object') {
        return false;
    }
    if(obj === null){
        return true;
    }
    if (Object.keys(obj).length > 0) {
        return false;
    }
    return true;
}