import moment from "moment";
import { useSelector } from "react-redux";

const Banner = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const subscriptionLimit = authState.subscriptionLimit;
    const endDate = subscription.endDate;
    const now = moment(); // Current date

    const diffInDays = moment(endDate).diff(now, 'days',true);

    // console.log(diffInDays)
    let text = '';

    if (diffInDays <= 0) {
        text = 'Your FREE Trial plan is expired !!!'
    } else if (diffInDays === 1) {
        text = 'Your FREE Trial plan is expiring in 1 day!'
    } else {
        text = `Your FREE Trial plan is expiring in ${Math.floor(diffInDays) + 1} day!`
    }

    if(!subscription.isFreeTrial){
        return <></>
    }
    return (
        <>
            <div className="h-[58px]" />
            <div className="fixed top-0 p-4 pt-2 left-0 z-10 w-full bg-star-light4">
                <div className="flex items-center justify-center rounded-lg py-2 px-2.5 gap-1  text-center border" style={{
                    borderColor: '#D9974A',
                    background: '#FFF5E9'
                }}>
                    <p className="text-bodyTwoBold" style={{ color: '#D9974A' }}>{text} </p> <p className="text-gray-6 text-bodyTwo">{subscriptionLimit.searchLimit - subscriptionLimit.searchUsage}/{subscriptionLimit.searchLimit} searches left</p>
                </div>
            </div>
        </>

    )
}

export default Banner;