import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import AuthGuard from './guard/authGuard';
import LogoutRequiredGuard from './guard/logoutRequiredGuard';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import OnBoarding from './pages/onboarding';
import { persistor, store } from './redux/store';
import VerifyLinkedin from './pages/onboarding/verifyLinkedin';
import Inspiration from './pages/inspiration';
import Plan from './pages/plan';
import ForgetPassword from './pages/auth/forgetPassword';
import ResetPassword from './pages/auth/resetPassword';
import { GOOGLE_CLIENT_KEY } from './utils/config';
import PaymentPending from './pages/payment/paymentPending';
import PaymentGuard from './guard/paymentGuard';
import PaymentFailed from './pages/payment/paymentFailed';
import LoginToken from './pages/auth/loginToken';

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_KEY}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <Routes>

                  {/* Auth Routes */}
                  <Route path="/login" element={<LogoutRequiredGuard><Login /></LogoutRequiredGuard>} />
                  <Route path="/loginToken" element={<LogoutRequiredGuard><LoginToken /></LogoutRequiredGuard>} />
                  <Route path="/register" element={<LogoutRequiredGuard><Register /></LogoutRequiredGuard>} />
                  <Route path="/onboarding/" element={<AuthGuard><OnBoarding /></AuthGuard>} />
                  <Route path="/onboarding/verifyLinkedin" element={<AuthGuard><VerifyLinkedin /></AuthGuard>} />

                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />

                  {/* User routes */}
                  <Route path="/" element={<PaymentGuard><Inspiration /></PaymentGuard>} />
                  <Route path="/plan" element={<PaymentGuard><Plan /></PaymentGuard>} />
                  <Route path="/payment-pending" element={<AuthGuard><PaymentPending /></AuthGuard>} />
                  <Route path="/payment-failed" element={<AuthGuard><PaymentFailed /></AuthGuard>} />
                </Routes>
              </Router>
              <ToastContainer />
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
