import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";

const features = [
    {
        title: "Inspiration Hub",
        description: "Browse over 8M viral posts to spark ideas and stay relevant."
    },
    {
        title: "Super Recommendations ",
        description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
    },
    {
        title: "Personalized AI Writer",
        description: "Create and edit posts in seconds—no more staring at a blank screen."
    },
];
const CustomPlan = () => {

    const color = '#FF90F6';
    const background = '#FFF4FE';

    return (
        <div className={`relative border flex flex-col  rounded-[20px] px-[26px] py-9 max-w-[355px]`} style={{ borderColor: color, background: background }}>
            <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div>
            <p className="text-headingThree " style={{ color: color }}>Enterprise</p>
            <p className="text-bodyTwo text-gray-6">For Agencies & Businesses</p>
            {/* <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{currencySymbol}{amountBeforeDiscount?.toLocaleString()}</del></p> */}
            <div className="flex items-center gap-2 mt-14">
                <p className="text-headingTwo text-space-default">Custom Prices</p>
            </div>
            <span className="text-bodyTwo text-gray-5">as per requirement</span>
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-[26px]">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <BadgeCheckFilled color={color} />
                                <div>
                                    <p className="text-bodyOne text-gray-6">{item.title}</p>
                                    <p className="text-subText text-gray-5">{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex items-center justify-center mt-5">
                <p className="text-button text-center" style={{ color: '#FF90F6' }}><span className="font-semibold">Contact us</span>  <br /> <span className="font-bold">hello@superpen.io</span></p>
            </div>
        </div>
    )
}
export default CustomPlan;