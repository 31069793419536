import { ArrowLeft, ArrowRight, Copy, FastArrowLeft, FastArrowRight } from "iconoir-react";
import { useState } from "react";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import ContentExpand from "../../component/ContentExpand";
import TextEditor from "../../component/text-editor";
import { copyToClipboard } from "../../component/text-editor/copyClipboard";
import { incrementUserProperty, setUserProperties, trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const LeftSide = ({ content, selectedComment }) => {
    const [show, setShow] = useState(true);
    const handleToggle = () => {
        setShow(prev => !prev);
    }
    return (
        <div className="rounded-[20px] p-4 overflow-auto h-[95vh] scrollbar" style={{
            background: 'rgba(245, 242, 255, 0.40)'
        }}>
            <div className="flex items-center gap-1">
                <LightBulbIcon />
                <p className="text-bodyOneBold text-star-dark">Your Inspiration</p>
                {
                    show && (
                        <div className="flex gap-2 text-bodyTwo text-star-default items-center cursor-pointer ml-auto" onClick={handleToggle}>
                            <p>Hide</p>
                            <FastArrowLeft />
                        </div>
                    )
                }
            </div>
            {
                !show && (
                    <div className="flex gap-2 justify-end text-bodyTwo text-star-default items-center cursor-pointer mt-2" onClick={handleToggle}>
                        <p>Show</p>
                        <FastArrowRight />
                    </div>
                )
            }
            {
                show && (
                    <>
                        <p className="text-bodyTwo text-space-default my-4">
                            Reference post
                        </p>
                        <div className="rounded-xl bg-white p-4">
                            <ContentExpand content={content} maxLines={3} defaultValue={!selectedComment?.length} />
                        </div>
                        <div className="mt-4">
                            {
                                selectedComment?.length > 0 && (
                                    <p className="text-bodyTwo text-space-default">Relevant comments</p>
                                )
                            }
                            <div className="flex flex-col mt-4 gap-4">
                                {
                                    selectedComment?.map((comment) => (
                                        <div key={comment._id} className="rounded-xl p-2 bg-star-light4 flex gap-2">
                                            <div className="min-w-5">
                                                <ThumbsUpIcon firstColor="#5F38DB" secondColor="#A489F3" />
                                            </div>
                                            <p className="text-bodyTwo text-gray-6">
                                                {comment?.commentText}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
const getDifferenceInSeconds = (date1, date2) => {
    const diffInMilliseconds = Math.abs(date2 - date1); // Get difference in milliseconds
    return Math.floor(diffInMilliseconds / 1000); // Convert milliseconds to seconds
};
const WritingScreen = (props) => {
    const {
        selectedComment,
        content,
        handleBack,
        handleNext,
        editorState,
        setEditorState,
        handleGenerateNewPost,
        isAiGenerated,
        postGenerating,
        onClose
    } = props;

    const trackCopyClicked = () => {
        trackEvent(MIXPANEL_EVENTS.CONTENT_COPY, {
            "Copy From": 'writing_screen',
            "Post Id": props.postId,
            "Post Source": props.source,
            "Generated with AI": isAiGenerated,
            "Manual changes": !isAiGenerated,
        })

        incrementUserProperty('post_copied');
    }
    const trackBackClicked = () => {
        trackEvent(MIXPANEL_EVENTS.WRITING_BACK, {
            "Remaining AI credits": 0,
            "Post Id": props.postId,
            "Generated with AI": isAiGenerated,
            "Manual changes": !isAiGenerated
        })
    }

    const trackAIClicked = () => {
        trackEvent(MIXPANEL_EVENTS.AI_CLICKED, {
            "Number of comments selected": selectedComment.length,
            "Post Id": props.postId,
            "Post Source": props.source,
            "Remaining AI credits": 0,
            "AI chosen stage": 'writing_page',
            "Manual before AI": true,
        })
    }

    const showPreviewClicked = () => {
        trackEvent(MIXPANEL_EVENTS.PREVIEW_CLICKED, {
            "Generated with AI": isAiGenerated,
            "Post Id": props.postId,
            "Post Source": props.source,
            "Manual changes": !isAiGenerated,
            "Time lapsed (start to preview)": getDifferenceInSeconds(props.startTime, new Date())
        })

        // console.log(props.startTime, new Date())
    }
    const disabled = editorState.getCurrentContent().getPlainText() === '';
    return (
        <div className="z-50 h-screen w-screen fixed top-0 left-0 p-5 bg-star-light2">
            <div className="flex  gap-5 h-[95vh]">

                {/* Left Side */}
                <div className="max-w-[32%]">
                    <LeftSide content={content} selectedComment={selectedComment} />
                </div>

                {/* Right Side */}
                <div className="flex flex-1 flex-col gap-5 rounded-[20px] bg-gray-moon p-[30px] h-[95vh]">
                    <div className="flex items-center justify-center gap-2">
                        {/* <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" /> */}
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                    </div>
                    <div>
                        <p className="text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Let’s start writing!
                        </p>
                        <p className="text-bodyOne">
                            Build on the reference post with your unique insight, please credit the original creator if you are drawing inspiration from their work!
                        </p>
                    </div>
                    <div className="h-full">
                        <TextEditor
                            postGenerating={postGenerating}
                            isAiGenerated={isAiGenerated}
                            handleGenerateNewPost={() => {
                                trackAIClicked();
                                handleGenerateNewPost();
                            }}
                            editorState={editorState}
                            setEditorState={setEditorState}
                        />
                    </div>
                    <div className="flex justify-between items-center mt-auto">
                        <button className="text-btn small" onClick={() => {
                            trackBackClicked();
                            onClose();
                        }}>
                            <ArrowLeft /> Back
                        </button>
                        <div className="flex gap-2">
                            <button className="text-btn small" onClick={() => {
                                trackCopyClicked();
                                copyToClipboard(editorState)
                            }}>
                                <Copy />
                                Copy
                            </button>
                            <button className="filled-btn small" disabled={disabled} onClick={() => {
                                showPreviewClicked();
                                handleNext();
                            }}>
                                <ArrowRight />
                                Show Preview
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default WritingScreen;