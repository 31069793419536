import api from "../utils/api";

export const loginService = async ({ email, password }) => {
    try {
        const response = await api.post('/auth/login', { email, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const loginTokenService = async ({ token }) => {
    try {
        const response = await api.post('/auth/token-login', { token });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const registerService = async ({ name, email, password }) => {
    try {
        const response = await api.post('/auth/register', { name, email, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const sendOTPService = async ({ email, name }) => {
    try {
        const response = await api.post('/auth/send-otp/email', { email, name });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const verifyOTP = async ({ email, otp }) => {
    try {
        const response = await api.post('/auth/verify-otp', { email, otp });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const googleLogin = async ({ accessToken }) => {
    try {
        const response = await api.post('/auth/google', { accessToken });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const sendPasswordResetLink = async ({ email }) => {
    try {
        const response = await api.post('/auth/send-password-reset-link', { email });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const resetPassword = async ({ userId, token, password }) => {
    try {
        const response = await api.post('/auth/password-reset', { userId, token, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
