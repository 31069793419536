import moment from "moment/moment";
import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";
import { useSelector } from "react-redux";

const PlanCard = (props) => {
    const authState = useSelector((state) => state.auth);

    const subscription = authState.subscription;

    const {
        isActive,
        className,
        name,
        billingPeriod,
        amount,
        amountBeforeDiscount,
        features,
        _id,
        currency,
        description,
        index = 0,
        handlePayment,
        isPending
    } = props;

    const currencySymbol = currency === 'INR' ? '₹' : '$';
    const tenure = billingPeriod === 'monthly' ? '/month' : '/year';

    const theme = [
        {
            color: '#2789EC',
            background: '#F0FAFF',
        }, {
            color: '#7145FD',
            background: '#F7F6FF',
        }, {
            color: '#FF90F6',
            background: '#FFF4FE',
        }
    ]

    const { color, background } = theme[index];
    const isBest = isActive ? false : index === 1;
    const actualAmount = billingPeriod === 'yearly' ? (amount / 12)?.toLocaleString() : amount?.toLocaleString();
    return (
        <div className={`relative border flex flex-col  rounded-[20px] px-[26px] py-9 ${className} max-w-[355px]`} style={{ borderColor: color, background: background }}>
            {isActive && <div className="absolute -top-[15px] left-0 right-0  m-auto rounded-lg text-white w-[fit-content] bg-star-default px-3 py-1">
                Current Plan 👇
            </div>}
            {
                isBest && <div className="absolute -top-[50px] left-0 right-0 w-full -z-10 rounded-[20px] text-white text-center bg-star-default  pt-4 py-10">
                    <p className="text-bodyOneBold md:text-headingFour">Best Value 🚀
                    </p>
                </div>
            }
            <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div>
            <p className="text-headingThree " style={{ color: color }}>{name}</p>
            <p className="text-bodyTwo text-nowrap text-gray-6">{description}</p>
            <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{currencySymbol}{amountBeforeDiscount?.toLocaleString()}</del></p>
            <div className="flex items-center gap-2">
                <p className="text-headingTwo text-space-default">{currencySymbol}{actualAmount}  </p>
                <span className="text-bodyTwo text-gray-5">{tenure}</span>
            </div>
            <p className="text-bodyOne text-gray-5">+ Taxes</p>
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-[26px]">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <BadgeCheckFilled color={color} />
                                <div>
                                    <p className="text-bodyOne text-gray-6">{item.title}</p>
                                    <p className="text-subText text-gray-5">{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                isActive && subscription.endDate && (
                    <div className="flex flex-col mt-8 items-center justify-center">
                        <p className="text-bodyThree text-gray-5">Subscription Renewal Date</p>
                        <p className="text-bodyOneBold">{moment(subscription.endDate).format("DD MMM'YY")}</p>
                    </div>
                )
            }
            {
                handlePayment && (
                    <div className="flex flex-col pt-5 items-center justify-center mt-auto">
                        <button disabled={isPending} className={`filled-btn ${color === '#2789EC' ? 'blue' : ''} `} onClick={()=>{
                            handlePayment(_id)
                        }}>Start FREE Trial</button>
                        <p className="text-bodyThree text-center text-gray-5 mt-2.5">Start your 7 Days free trial & get access to all premium features
                        </p>
                    </div>
                )
            }
        </div>
    )
}
export default PlanCard;