import { useQuery } from '@tanstack/react-query';
import { DiceThree, Filter } from "iconoir-react";
import { useEffect, useState } from "react";
import LoaderGif from "../../asset/gif/loader.gif";
import MicstageIcon from "../../asset/svg/MicStageIcon";
import NotFoundImage from "../../asset/svg/notFound.svg";
import PenNibIcon from "../../asset/svg/PenNibIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import SearchField from "../../component/custom-form/SearchField";
import SelectBox from "../../component/custom-form/SelectBox";
import MasonaryLayout from "../../component/MasonaryLayout";
import PostCard from "../../component/PostCard";
import useScrolledDown from "../../hooks/useScrolledDown";
import MainLayout from "../../layout/main";
import { fetchLinkedInPosts } from "../../services/post.service";
import { useDispatch, useSelector } from 'react-redux';
import { isObjectEmpty } from '../../utils/helper';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';
import { updateSearchUsage } from '../../redux/slice/auth.slice';

const likesOption = [
    {
        label: '100 - 200',
        value: {
            min: 100,
            max: 200
        }
    },
    {
        label: '201 - 500',
        value: {
            min: 201,
            max: 500
        }
    },
    {
        label: '501 - 1000',
        value: {
            min: 501,
            max: 1000
        }
    },
    {
        label: '1000+',
        value: {
            min: 1000,
            max: undefined
        }
    },
]

const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny'
    },
    {
        label: 'Insightful',
        value: 'insightful'
    },
    {
        label: 'Share worthy',
        value: 'shareworthy'
    },
]

const contentLengthOption = [
    {
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    },
    {
        label: 'Medium',
        value: {
            min: 75 * 5,
            max: 200 * 5
        }
    },
    {
        label: 'Long',
        value: {
            min: 200 * 5,
        }
    },
]

const trackFilterEvent = (eventName, name, value) => {
    trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
        "Filter type": name
    })
    trackEvent(eventName, {
        [`${name} Filter`]: value,
    });
}

const Inspiration = () => {
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    let { scrolledDown: sticky, isScrollingDown } = useScrolledDown(40);
    const [text, setText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentLengthFilter, setContentLengthFilter] = useState({});
    const [isUnstuck, setIsUnstuck] = useState(false);

    const query = {
        search: searchText,
        userGoals: searchText ? undefined : authState?.interest ? authState.interest.join(',') : undefined,
        minContentLength: contentLengthFilter?.value?.min,
        maxContentLength: contentLengthFilter?.value?.max,
        minLikes: likeFilter?.value?.min,
        maxLikes: likeFilter?.value?.max,
        reactionFunny: contentToneFilter?.value === 'funny' ? true : undefined,
        reactionInsightful: contentToneFilter?.value === 'insightful' ? true : undefined,
        reactionShareworthy: contentToneFilter?.value === 'shareworthy' ? true : undefined,
    };
    const { data, isFetching: isLoading, refetch } = useQuery({
        queryKey: ['linkedin-posts', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchLinkedInPosts(queryKey[1]);
            dispatch(updateSearchUsage());
            return response;
        },
        staleTime: Infinity,
        retry: false
    });

    // console.log(isFetching)
    const handlePressEnter = (value) => {
        setText(value);
        setSearchText(value);
    }
    const handleClearSearch = () => {
        setText('');
        setSearchText('');
    }

    const resetAllFilter = () => {
        trackEvent(MIXPANEL_EVENTS.FILTERS_REMOVED);
        setLikeFilter({});
        setContentToneFilter({});
        setContentLengthFilter({});
    }

    const activeFilter = Object.keys(likeFilter).length || Object.keys(contentLengthFilter).length || Object.keys(contentToneFilter).length
    const suggestions = query.search ? data?.relatedTags || [] : null;

    const filterCount = !isObjectEmpty(likeFilter) + !isObjectEmpty(contentToneFilter) + !isObjectEmpty(contentLengthFilter);
    sticky = isUnstuck ? false : sticky;

    useEffect(() => {
        setIsUnstuck(false);
    }, [isScrollingDown])
    const handleUnStick = () => {
        setIsUnstuck(true);
    }

    const handleClickSuggested = (suggest) => {
        trackEvent(MIXPANEL_EVENTS.SUGGESTION_CLICKED, {
            "Original search": searchText,
            "Suggested word": suggest
        })
        handlePressEnter(suggest);
    }

    const metaData = {
        search: searchText,
        defaultRecommenedPost: searchText ? false : true,
        postTone: contentToneFilter?.value
    }

    const { subscription } = authState;
    return (
        <MainLayout>
            <div className="rounded-2xl  relative bg-star-light3" style={{
                marginTop: subscription.isFreeTrial ? 65 : undefined,
            }}>
                <div className='rounded-2xl fixed z-20 top-0 w-full right-0' style={{
                    width: 'calc(100% - 191px)',
                    top: subscription.isFreeTrial ? 65 : undefined
                }}>
                    <div className={`flex bg-star-light3 p-4 items-center ${sticky ? 'flex-row gap-4' : 'flex-col'}`}>
                        <div className="w-[504px]">
                            <SearchField value={text} onChange={setText} onPressEnter={handlePressEnter} onClear={handleClearSearch} placeholder='Search any topic and discover viral posts' />
                        </div>
                        <div className={`${sticky ? '' : 'mt-8'}`}>
                            {
                                suggestions ? (
                                    <div className="flex items-center gap-3">
                                        <p className="text-bodyThree text-gray-5">Suggestions</p>
                                        {
                                            suggestions.slice(0, 3).map((suggest, index) => (
                                                <p onClick={() => { handleClickSuggested(suggest); }} key={index} className="flex flex-col text-bodyThree cursor-pointer bg-clip-text text-transparent bg-base-gradient">
                                                    {suggest}
                                                    <span className="border-b -mt-1 w-full border-star-light"></span>
                                                </p>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <p className="bg-clip-text text-transparent bg-base-gradient text-bodyThree">Recommended for you</p>
                                )
                            }
                        </div>
                        {
                            !sticky && (
                                <div className="flex mt-4 items-center gap-2">
                                    <SelectBox
                                        value={likeFilter}
                                        icon={<ThumbsUpIcon />}
                                        label="Likes"
                                        options={likesOption}
                                        onRemoveFilter={() => { setLikeFilter({}) }}
                                        onChange={(val) => {
                                            trackFilterEvent(MIXPANEL_EVENTS.FILTER_LIKES, 'Likes', val.label);
                                            setLikeFilter(val);
                                        }}
                                    />
                                    <SelectBox
                                        value={contentToneFilter}
                                        icon={<MicstageIcon />}
                                        label="Content Tone"
                                        options={contentToneOption}
                                        onRemoveFilter={() => { setContentToneFilter({}) }}
                                        onChange={(val) => {
                                            trackFilterEvent(MIXPANEL_EVENTS.FILTER_TONE, 'Tone', val.label);
                                            setContentToneFilter(val);
                                        }}
                                    />
                                    <SelectBox
                                        value={contentLengthFilter}
                                        icon={<PenNibIcon />}
                                        label="Length"
                                        options={contentLengthOption}
                                        onRemoveFilter={() => { setContentLengthFilter({}) }}
                                        onChange={(val) => {
                                            trackFilterEvent(MIXPANEL_EVENTS.FILTER_LENGTH, 'Length', val.label);
                                            setContentLengthFilter(val);
                                        }}
                                    />
                                    {
                                        activeFilter > 0 && (
                                            <button onClick={resetAllFilter} className="text-btn small text-gray-6">Remove all filters</button>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            sticky && (
                                <div className="ml-auto">
                                    <button className="icon-outline-btn small relative" onClick={handleUnStick}>
                                        <Filter />
                                        {
                                            filterCount > 0 && (
                                                <div className='flex items-center justify-center absolute -top-1 -right-1 rounded-full w-[13px] h-[13px] bg-mars-default text-white text-subTextBold'>
                                                    {filterCount}
                                                </div>
                                            )
                                        }
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="p-4 pt-[200px]">
                    {
                        isLoading && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={LoaderGif} width={200} alt="loading" />
                                <p className="text-bodyThreeBold text-star-light">Hang tight, inspiration is on the way!</p>
                            </div>
                        )
                    }
                    {
                        data?.data?.length === 0 && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={NotFoundImage} alt="notFound" />
                                <p className="text-bodyThreeBold text-star-light text-center"> Posts not found for your query! <br />
                                    Try using some alternate keywords</p>
                            </div>
                        )
                    }
                    {
                        !isLoading && (
                            <MasonaryLayout>
                                {
                                    data?.data?.map((postItem) => (
                                        <div key={postItem.postId} className="flex-1">
                                            <PostCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                        </div>
                                    ))
                                }
                            </MasonaryLayout>
                        )
                    }
                    {
                        !isLoading && data?.data?.length > 0 && (
                            <div className='flex items-center justify-center my-4'>
                                <button className='outlined-btn small border-none' onClick={() => refetch()}>
                                    <DiceThree />
                                    Renew inspiration feed
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
        </MainLayout>
    )
}

export default Inspiration;