import { ChatLines, Copy, KeyframesCouple, Link, PasteClipboard } from "iconoir-react";
import moment from "moment/moment";
import { useState } from "react";
import AvatarIcon from "../asset/svg/AvatarIcon";
import HeartAndThumbIcon from "../asset/svg/HeartAndThumbIcon";
import RepeatIcon from "../asset/svg/RepeatIcon";
import RecreatePost from "../pages/RecreatePost";
import ContentExpand from "./ContentExpand";
import { unsecuredCopyToClipboard } from "./text-editor/copyClipboard";
import { isObjectEmpty } from "../utils/helper";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";
import { incrementUserProperty, trackEvent } from "../utils/mixpanel";
import { formatToK } from "../utils/formatter";

const PollComponent = ({ data }) => {
    return (
        <div className="rounded-xl mt-2 bg-star-light3 px-2 py-1">
            <p className="text-bodyThreeBold text-gray-6">{data.question}</p>
            <div className="flex flex-col gap-2 mt-2">
                {data.options.map((option, index) => (
                    <p className="text-bodyThree text-space-dark" key={index}>{option}</p>
                ))}
            </div>
        </div>
    )
}

const ImageComponent = ({ imageURLs }) => {
    const imageCount = imageURLs.length;

    if (imageCount === 0) {
        return null;
    }

    if (imageCount === 1) {
        return (
            <div className="mt-2 rounded-lg overflow-hidden mx-auto">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full object-cover max-h-[360px]" />
            </div>
        );
    }

    if (imageCount === 2) {
        return (
            <div className="grid mt-2 grid-cols-2 gap-1">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
                <img src={imageURLs[1]} alt="image2" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
            </div>
        );
    }

    if (imageCount >= 3) {
        return (
            <div className="grid mt-2 grid-cols-2 gap-1 overflow-hidden">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg object-cover max-h-[260px]" />

                <div className="grid grid-rows-2 gap-1 h-full">
                    <div>
                        <img src={imageURLs[1]} alt="image2" className="w-full h-[120px] rounded-lg object-cover" />
                    </div>
                    <div className="relative">
                        <img src={imageURLs[2]} alt="image3" className="w-full h-[120px] rounded-lg object-cover" />
                        {
                            imageCount > 3 && (
                                <div className="absolute z-10 inset-0 flex justify-center items-center bg-gradient-to-b from-transparent to-black rounded-lg">
                                    <p className="text-subHeadingOne text-white">
                                        +{imageCount - 3}
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        );
    }

};

const ArticleComponent = ({ data }) => {
    return (
        <a href={data.articleURL} rel="noreferrer" target="_blank">
            <div className="rounded-xl mt-2 bg-star-light3 flex p-2 gap-2 items-center">
                <div className="w-[95px] rounded-lg overflow-hidden">
                    <img src={data.smallImageURL || data.largeImageURL} className="w-full h-full" alt="article-img" />
                </div>
                <div className="flex flex-col">
                    <p className="text-subTextBold text-gray-6">{data.title}</p>
                    <p className="text-subText text-star-default">{data.subtitle}</p>
                </div>
            </div>
        </a>
    )
}

const postTones = ({ totalLikeCount, sharesCount, entertainmentCount, interestCount }) => {
    let tempArray = [];
    if ((entertainmentCount / totalLikeCount) >= 0.2) {
        tempArray.push('Funny');
    }
    if ((interestCount / totalLikeCount) >= 0.3) {
        tempArray.push('Insightful')
    }

    if (sharesCount > 20) {
        tempArray.push('Shareworthy')
    }
    return tempArray;
}

const PostCard = (props) => {
    const [open, setOpen] = useState(false);

    const {

        content,
        sharesCount,
        commentsCount,
        totalLikeCount,
        imageURL,
        videoURL,
        publishedDate,
        userId,
        url,
        pollComponent,
        articleComponent,
        postType,
        metaData,
        source,
        postId,
        wordCount,
        entertainmentCount,
        interestCount
    } = props;

    const handleCopyClipBoard = () => {
        unsecuredCopyToClipboard(content)
    }

    const trackingData = {
        "Default recommended post": metaData.defaultRecommenedPost,
        "Post source": source,
        "Post ID": postId,
        "Search keyword": metaData.search,
        "Number of reactions": totalLikeCount,
        "Number of comments": commentsCount,
        "Type of post": imageURL?.length > 0 ? 'image' : videoURL ? 'video' : !isObjectEmpty(pollComponent) ? 'poll' : !isObjectEmpty(articleComponent) ? 'article' : 'text',
        "Tone of post": postTones({ totalLikeCount, entertainmentCount, interestCount, sharesCount }).join(','),
        "Content length": wordCount
    }

    return (
        <>
            {
                open && <RecreatePost {...props} postType={postType} startTime={new Date()} onClose={() => setOpen(false)} />
            }
            <div className="min-w-[360px]">
                <div className="px-4 py-2 flex justify-between items-center bg-white rounded-t-xl ">
                    <div className="flex gap-2 items-center">
                        <div>
                            <AvatarIcon width="36" height="36" src={userId?.profilePhoto} />
                        </div>
                        <div>
                            <p className="text-bodyTwo text-space-dark">{userId?.fullName || userId?.username}</p>
                            <p className="text-subText text-gray-5">{moment(publishedDate).fromNow()}</p>
                        </div>
                    </div>
                    <a href={url} target="_blank" rel="noreferrer" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.POST_CLICK, trackingData);
                        incrementUserProperty('post_clicked');
                    }}>
                        <Link width={24} height={24} color="#A489F3" />
                    </a>
                </div>
                <div className="p-4 rounded-b-xl bg-gray-moon hover:bg-white">
                    <ContentExpand content={content} maxLines={7} tracker={true} trackingData={trackingData} />
                    {videoURL && (
                        <div className="h-[228px] rounded-lg overflow-hidden my-4">
                            <video src={videoURL} controlsList="nodownload" controls className="w-full h-full" />
                        </div>
                    )}
                    {
                        !isObjectEmpty(pollComponent) && (
                            <PollComponent data={pollComponent} />
                        )
                    }
                    {
                        imageURL?.length > 0 && (
                            <ImageComponent imageURLs={imageURL} />
                        )
                    }
                    {
                        !isObjectEmpty(articleComponent) && (
                            <ArticleComponent data={articleComponent} />
                        )
                    }
                    <div className="flex justify-between items-center my-4">
                        <div className="flex items-center gap-1">
                            <div >
                                <HeartAndThumbIcon />
                            </div>
                            <p className="text-bodyThree">{formatToK(totalLikeCount)}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="flex gap-[6px] items-center">
                                <ChatLines width={20} height={20} color="#A489F3" />
                                <p className="text-bodyThree">{formatToK(commentsCount)}</p>
                            </div>
                            <div className="flex gap-[6px] items-center">
                                <RepeatIcon width={20} height={20} color="#A489F3" />
                                <p className="text-bodyThree">{formatToK(sharesCount)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button className="filled-btn small w-full text-base" onClick={() => {
                            trackEvent(MIXPANEL_EVENTS.RECREATE_CLICKED, trackingData);
                            incrementUserProperty('recreate_clicked');
                            setOpen(true);
                        }}>
                            <KeyframesCouple />
                            Recreate
                        </button>
                        <button className="text-btn small" onClick={handleCopyClipBoard}>
                            <Copy />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PostCard;