import { NavArrowDown } from "iconoir-react";
import { useState, useEffect, useRef } from "react";

const SelectBox = ({ value, icon, label, options, onRemoveFilter, onChange }) => {
    const [showModel, setShowModel] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowModel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className="rounded-lg cursor-pointer bg-gray-moon flex gap-2 items-center px-2 py-1"
                onClick={() => { setShowModel(!showModel) }}
            >
                <div>
                    {icon}
                </div>
                <p className="text-bodyThree text-gray-5">
                    {label}
                </p>
                {
                    value.label && <>
                        <p className="text-bodyThree text-gray-5">|</p>
                        <p className="text-bodyThreeBold text-gray-6">{value.label}</p>
                    </>
                }
                <NavArrowDown width={15} height={15} color="#8B8699" />
            </div>

            {showModel && (
                <div className="show absolute right-0 z-20 top-[35px] w-[140px]  p-2 rounded-lg bg-white border-[0.5px] border-gray-4 shadow-purple" >
                    <p className="text-bodyThree text-gray-4 mb-4">{label}</p>
                    <div className="flex flex-col gap-2">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    onChange(option);
                                    setShowModel(false);  // Close dropdown after selecting an option
                                }}
                                className={`px-2 py-[2px] text-bodyTwo rounded-lg text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light
                                     ${option.value === value.value ? 'bg-star-light4 text-star-light' : ''}`}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                    {onRemoveFilter && (
                        <button className="text-btn small mt-4" onClick={() => { setShowModel(false); onRemoveFilter() }}>
                            Remove Filter
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectBox;
