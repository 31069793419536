import { CheckCircle, XmarkCircle } from "iconoir-react";
import React, { useState, useEffect } from "react";
import {
    hookDecorator,
    contentLengthDecorator,
    spacingDecorator,
    formattingDecorator,
    emojiDecorator,
    hashtagDecorator,
} from "./decorator";
import { contentLengthValidator, emojiValidator, formattingValidator, hashtagValidator, hookValidator, spacingValidator } from "./validator";

const DecoratorValidation = ({ applyDecorator, removeDecorator, editorState }) => {
    const [failedValidations, setFailedValidations] = useState({});

    // Function to check which validations are failing
    useEffect(() => {
        const text = editorState.getCurrentContent().getPlainText();
        const contentBlock = editorState.getCurrentContent();

        const newFailedValidations = {
            hook: hookValidator(text),
            contentLength: contentLengthValidator(text),
            spacing: spacingValidator(text),
            formatting: formattingValidator(text, contentBlock),
            emojis: emojiValidator(text),
            hashtags: hashtagValidator(text),
        };
        setFailedValidations(newFailedValidations);
    }, [editorState]);

    const validators = [
        {
            name: 'Hook',
            decorator: hookDecorator,
            isValid: failedValidations.hook,
            info: 'To increase engagement, it is recommended to add a blank line towards the beginning of your post'
        },
        {
            name: 'Content Length',
            decorator: contentLengthDecorator,
            isValid: failedValidations.contentLength,
            info: "Very lengthy posts don't typically do well on LinkedIn. Try to keep it within 400 words."
        },
        {
            name: 'Spacing',
            decorator: spacingDecorator,
            isValid: failedValidations.spacing,
            info: "Keeping paragraphs short and adding space between lines makes the post more readable"
        },
        {
            name: 'Formatting',
            decorator: formattingDecorator,
            isValid: failedValidations.formatting,
            info: "Formatting is good, but over-formatting is bad. Make sure you only bold things you want to highlight"
        },
        {
            name: 'Emojis',
            decorator: emojiDecorator,
            isValid: failedValidations.emojis,
            info: "Don't use too many emojis in your post, try to cap it at 4"
        },
        {
            name: '#Hashtags',
            decorator: hashtagDecorator,
            isValid: failedValidations.hashtags,
            info: "It is recommended to add upto 4 #hashtags at the end (not in the body) to improve the reach of your post"
        },
    ];

    return (
        <div className='mt-2 flex items-center gap-2 flex-wrap'>
            {validators.map((validator, index) => (
                <div className="tooltip-wrapper">
                    <div
                        key={index}
                        className={`validator-btn ${validator.isValid ? 'checked' : ''} `}
                        {...(!validator.isValid && {
                            onMouseEnter: () => applyDecorator(validator.decorator),
                            onMouseLeave: () => removeDecorator(),
                        })}
                    >
                        {validator.isValid ? (
                            <CheckCircle width={20} height={20} />
                        ) : (
                            <XmarkCircle width={20} height={20} />
                        )}
                        <p>{validator.name}</p>
                    </div>
                    <div className="hidden tooltip absolute w-[380px] -top-[80px] left-0 bg-white p-[15px] text-bodyThree text-gray-5" style={{
                        borderRadius: '8px 8px 8px 0px',
                        boxShadow: '0px 14px 32px 0px rgba(39, 39, 47, 0.06)'
                    }}>
                        {validator.info}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DecoratorValidation;
